.about-us {
    max-width: 800px;
    margin: 0 auto;
}

.about-us h2.title {
    font-size: 44px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 4rem;
}

.about-us img {
    width: 100%;
    max-width: 150px;
}

.about-us h3 {
    font-size: 1.25rem;
}

.about-us p {
    font-size: 1.1rem;
}

@media (min-width: 1200px) {
    .about-us h2.title {
        font-size: 65px;
    }

    .about-us img {
        max-width: 200px;
    }
}

.fw-bold {
    font-weight: 700;
}

.underlined-link {
    color: var(--primary-color);
}