#homepage #mainContent {
    padding: 0;
}

#homepage .container {
    max-width: unset;
}


/* Slider */
.slick-slider .chevron-right,
.slick-slider .chevron-left {
    width: 60px;
    height: 60px;
    padding: 20px;
    z-index: 11;
}

.slick-slider .chevron-right {
    right: clamp(0rem, 0.5vw, 1rem);
}

.slick-slider .chevron-left {
    left: clamp(0rem, 0.5vw, 1rem);
}