.faq h2.title {
    font-size: 45px;
    margin-bottom: 4rem;
    color: var(--dark-light-color);
}

.accordion {
    max-width: 800px;
    color: rgba(0, 0, 0, 0.75);
}

.accordion .bg-divider {
    background-color: rgba(225, 225, 225, 0.75);
}

.accordion .text-large {
    font-size: 1.3rem;
}

.accordion .text {
    font-size: 1.1rem;
    letter-spacing: 0.5px;
}

.accordion .answer {
    padding: 0 2rem 1.5rem;
}