.banner .slick-slider {
    max-width: unset;
}

.banner .slick-dots {
    bottom: 1.5rem;
}

.banner .slide {
    background-position: top center;
    background-size: cover;
    width: 100%;
    height: clamp(550px, 30vw, 700px);
}

.banner .slide .container {
    flex-direction: column;
    align-items: end;
    padding: 2rem;
    margin: 0 0 0 auto;
    width: 100%;
}

.banner .slide .container {
    align-items: center;
    justify-content: center;
    max-width: 65%!important;
}

.banner .slide .container .img-container {
    margin: 0 auto;
}

.banner .slide .keyRing {
    width: 150px;
    height: unset;
    max-height: 200px;
    margin: auto 0;    
}

.banner .banner-title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    line-height: 1.15;
}

.banner-title br + span {
    font-size: 132%;
    letter-spacing: 0.05em;
}

@media (min-width: 500px) {
    .banner .slide .container {
        max-width: 50%!important;
    }
}

@media (min-width: 680px) {
    .banner .slide .keyRing {
        width: 200px;
        max-height: 250px;
    }

    .banner .banner-title {
        font-size: 1.75rem;
    }
}

@media (min-width: 992px) {
    .banner .slide .container {
        max-width: 60%!important;
    }

    .banner .slide .keyRing {
        width: 250px;
        max-height: 300px;
    }

    .banner .banner-title {
        font-size: 2.5rem;
    }
}

@media (min-width: 1200px) {
    .banner .slide .container {
        flex-direction: row;
        justify-content: space-between;
        padding: 4rem;
        max-width: 1400px!important;
        margin: 0 auto;
    }

    .banner .slide .img-container {
        margin: auto 0!important;
    }

    .banner .slide .keyRing {
        min-width: 250px;
    }

    .banner h2 {
        font-size: 2.875rem;
    }

    .banner h3 {
        font-size: 2rem;
    }
}

@media (max-width: 1200px) {
    .banner .slide .container {
        width: fit-content;
    }
}
