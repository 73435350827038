.paw-card {
    width: 100%;
    max-width: 300px;
    margin: 1rem;
}

.paw-card svg {
    max-width: 300px;
    filter: drop-shadow(0px 0.5rem 0.5rem rgba(0, 0, 0, 0.15));
}

.paw-card .paw-content {
    top: 2.5rem;
    left: calc((100% - 210px) / 2);
    width: 100%;
    max-width: 210px;
}

.paw-card .number {
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 0.5rem;
}

.paw-card .title {
    font-size: 1.75rem;
    line-height: 0.9;
    font-weight: 600;
    margin-bottom: 0.75rem;
    border-bottom: none;
}

.paw-card .description {
    font-size: 1rem;
    font-weight: 400;
}

.paw-card .fill-primary-light .description {
    color: var(--dark-color);
}