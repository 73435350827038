.benefits .container {
    max-width: 1200px!important;
}

.benefits .title {
    flex-direction: column;
}

.benefits .title svg {
    max-width: 300px;
}

.benefits .title h2 {
    font-size: 2rem;
}

.benefits .item {
    /* width: 100%; */
    /* max-width: 300px; */
    width: 300px;
    height: 300px;
    border-radius: 1rem;
}

.benefits .item h3 {
    font-size: 1.4rem;
}

.benefits .item svg {
    max-width: 100px;
    max-height: 120px;
}


/* Flip the card */
/* .benefits .item {
    height: 300px;
    width: 300px;
    transform-style: preserve-3d;
    padding: 0;
    position: relative;
    transition: transform 0.75s;
}

.benefits .item:hover {
    transform: rotateY(180deg);
}

.benefits .front-card,
.benefits .back-card {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 1rem;
    transition: all 0.5s;
    backface-visibility: hidden;
}

.benefits .front-card:hover {
    backface-visibility: hidden;
}

.benefits .back-card {
    backface-visibility: hidden;
    transform: rotateY(180deg);
} */