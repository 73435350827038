input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea {
    font-size: 16px !important;
    -webkit-text-size-adjust: 100%;
}

/* Específicamente para iOS */
@supports (-webkit-touch-callout: none) {
    input, textarea, select {
        font-size: 16px !important;
    }
} 