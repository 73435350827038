#footer {
    font-size: 12px;
    z-index: 50;
}

#footer .logo {
    width: 70px;
    height: 70px;
}

#footer .content {
    flex-direction: column;
}

#footer .flex.justify-between p {
    position: relative;
}

#footer .flex.justify-between .instagram {
    width: 13px;
    height: 13px;
}

@media (min-width: 461px) {
    #footer .content {
        flex-direction: row;
    }
}

/* Cuando PP y TC están en líneas diferentes */
@media (max-width: 400px) {
    .separator-1 {
        display: none;
    }
}

/* Cuando los textos y los íconos están en líneas diferentes */
@media (max-width: 500px) {
    .separator-2 {
        display: none;
    }
}
