@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --danger-color: #D83431;
    --dark-color: #1D1E1E;
    --dark-light-color: #3B3B3D;
    --gray-color: #D8D8D8;
    --light-color: #F8F7F7;
    --light-gray-color: #D6D5D5;
    --primary-color: #D4801A;
    --primary-light-color: #DA984C;
    --primary-dark-color: #A36A0A;
    --secondary-color: #417ece;
    --secondary-light-color: #6a9eeb;
    --success-color: #8BAF56;
    --warning-color: #F9C74F;
    --navbar-height: 80px;
    --footer-height-sm: 194px;
    --footer-height: 168px;
}

html,
#root,
main,
div[data-overlay-container] {
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
}

div[data-overlay-container] {
    overflow: hidden;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    margin: 0;
    padding: 0;
    min-height: 100%;
    height: 100%;
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 100%;
    height: 100%;
}

.page form {
    max-width: 400px;
    margin: 0 auto;
    z-index: 10;
}

.container {
    max-width: 1000px;
    margin: 0 auto;
}

h1.main-title {
    color: #1C1D1E;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    line-height: 1.25;
    padding-bottom: 1rem;
}

.title .main-title {
    border-bottom: 2px solid var(--primary-color);
}

.btn-dark {
    background-color: #1C1D1E;
    color: #fff;
}

div[data-slot="input-wrapper"] {
    border-color: var(--primary-light-color);
    transition: all 0.3s;
}

div[data-slot="input-wrapper"]:hover {
    border-color: var(--primary-color);
    transition: all 0.3s;
}

.container {
    max-width: 1024px;
    margin: 0 auto;
}



/* Background */
.bg-danger {
    background-color: var(--danger-color);
}

.bg-dark {
    background-color: var(--dark-color);
}

.bg-dark-light {
    background-color: var(--dark-light-color);
}

.bg-light {
    background-color: var(--light-color);
}

.bg-light-gray {
    background-color: var(--light-gray-color);
}

.bg-primary {
    background-color: var(--primary-color);
}

.bg-primary-light {
    background-color: var(--primary-light-color);
}

.bg-secondary {
    background-color: var(--secondary-color);
}

.bg-secondary-light {
    background-color: var(--secondary-light-color);
}

.bg-success {
    background-color: var(--success-color);
}

/* Border */
.border-success {
    border: 1px solid var(--success-color);
}

.border-dark-light {
    border: 1px solid var(--dark-light-color);
}

.border-b-primary {
    border-bottom-color: var(--primary-color);
}

/* Color */
.color-dark-light {
    color: var(--dark-light-color);
}

.color-light {
    color: var(--light-color);
}

.color-primary {
    color: var(--primary-color);
}

.color-primary-light {
    color: var(--primary-light-color);
}

.color-secondary {
    color: var(--secondary-color);
}

.color-secondary-light {
    color: var(--secondary-light-color);
}

.text-success,
.\!text-success {
    color: var(--success-color) !important;
}

.text-danger,
.\!text-danger {
    color: var(--danger-color) !important;
}

.text-primary,
.\!text-primary {
    color: var(--primary-color) !important;
}

/* Fill */
.fill-danger {
    fill: var(--danger-color);
}

.fill-primary {
    fill: var(--primary-color);
}

.fill-primary-light {
    fill: var(--primary-light-color);
}

.fill-light {
    fill: var(--light-color);
}

.fill-dark {
    fill: var(--dark-color);
}

.fill-dark-light {
    fill: var(--dark-light-color);
}

.fill-secondary {
    fill: var(--secondary-color);
}

.fill-success {
    fill: var(--success-color);
}

/* Font-weight */
.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.gap-8 {
    gap: 2rem;
}

/* Margin */
.m-4 {
    margin: 2rem;
}

/* Top */
.mt-1 {
    margin-top: 0.5rem;
}

.mt-2 {
    margin-top: 1rem;
}

.mt-3 {
    margin-top: 1.5rem;
}

.mt-4 {
    margin-top: 2rem;
}

.mt-6 {
    margin-top: 3rem;
}

.mt-10 {
    margin-top: 5rem;
}

.mt-14 {
    margin-top: 7rem;
}

/* Right */
.mr-1 {
    margin-right: 0.5rem;
}

.mr-2 {
    margin-right: 1rem;
}

.mr-4 {
    margin-right: 2rem;
}

.mr-12 {
    margin-right: 6rem;
}

/* Bottom */
.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.5rem;
}

.mb-2 {
    margin-bottom: 1rem !important;
}

.mb-3 {
    margin-bottom: 1.5rem;
}

.mb-4 {
    margin-bottom: 2rem;
}

.mb-5 {
    margin-bottom: 2.5rem;
}

.mb-6 {
    margin-bottom: 3rem;
}

.mb-8 {
    margin-bottom: 4rem;
}

/* Left */
.ml-2 {
    margin-left: 1rem;
}

.ml-12 {
    margin-left: 6rem;
}

/* Y Axis */
.my-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.my-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

/* X axis */
.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.mx-4 {
    margin-left: 2rem;
    margin-right: 2rem;
}

/* Padding */
.p-1 {
    padding: 0.5rem;
}

.p-2 {
    padding: 1rem;
}

.p-3 {
    padding: 1.5rem;
}

.p-4 {
    padding: 2rem;
}

.p-6 {
    padding: 3rem;
}

.p-8 {
    padding: 4rem;
}

.pt-0 {
    padding-top: 0 !important;
}

/* Bottom */
.pb-4 {
    padding-bottom: 2rem;
}

/* Y axis */
.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.py-1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

/* .py-2 { padding-top: 1rem; padding-bottom: 1rem; } */
.py-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.py-4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.py-8 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

/* X axis */
.px-0 {
    padding-left: 0;
    padding-right: 0;
}
.px-2 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.px-4 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.px-6 {
    padding-left: 3rem;
    padding-right: 3rem;
}

.px-8 {
    padding-left: 4rem;
    padding-right: 4rem;
}


/* Text */
.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.text-start {
    text-align: start;
}


/* Width */
.w-1\/2 {
    width: 50%;
}


/* Z Index */
.z-1 {
    z-index: 1;
}

.z-2 {
    z-index: 2;
}

.z-9 {
    z-index: 9;
}

.z-10 {
    z-index: 10;
}


.min-h-screen {
    min-height: calc(100vh - var(--navbar-height) - var(--footer-height) - 4rem);
}

.h-screen-nav {
    height: calc(100vh - 80px);
}

#mainContent {
    min-height: calc(100vh - var(--navbar-height) - var(--footer-height-sm));
}

.bold {
    font-weight: bold;
}

.form-small {
    max-width: 400px;
}

.text-balance {
    text-wrap: balance;
}


/* Google Login */
.separator {
    display: flex;
    justify-content: center;
    position: relative;
    width: 300px;
    text-align: center;
}

.separator .separator-line {
    position: relative;
}

.separator .separator-line::before {
    position: absolute;
    content: '';
    top: 12px;
    width: 100px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.25);
}

.separator .separator-line.separator-left::before {
    left: calc(((300px - 100px) / 2) - 70px);
}

.separator .separator-line.separator-right::before {
    right: calc(((300px - 100px) / 2) - 70px);
}

.separator .separator-text {
    color: rgba(0, 0, 0, 0.75);
}

#googleLoginButton {
    width: 100%;
    max-width: 300px;
    margin: 1rem auto 0;
    z-index: 10;
}

#googleLoginButton div:first-child {
    width: 100%;
}


#googleLoginButton div:first-child iframe {
    min-width: 300px;
    width: 100% !important;
    margin: 0 !important;
}

iframe#webpack-dev-server-client-overlay {
    display: none !important
}


.autocomplete .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.125);
}

.button .content span {
    max-width: unset !important;
}

.small {
    font-size: 0.75rem;
}


/* Links */
.underlined-link {
    position: relative;
    color: var(--light-color);
}

.underlined-link::after {
    position: absolute;
    content: '';
    bottom: -0.33rem;
    left: 50%;
    width: 0;
    height: 1.5px;
    background: var(--light-color);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.underlined-link.underlined-link-primary {
    color: var(--primary-color);   
}

.underlined-link.underlined-link-primary::after {
    background: var(--primary-light-color);
}

.underlined-link.underlined-link-dark {
    color: var(--dark-color);
}

.underlined-link.underlined-link-dark::after {
    background: var(--dark-color);
}

.underlined-link:hover::after {
    width: 80%;
    left: 10%;
}


/* Modal */
.modal-header h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    color: var(--dark-light-color);
}

/* Modal close button should have z-index = 2 */
button[aria-label="Close"] {
    z-index: 2;
}

.modal-header svg {
    width: 2rem;
    height: 2rem;
}

.modal-header::after {
    content: '';
    position: absolute;
    bottom: 1rem !important;
    display: block;
    width: 50%;
    height: 2px;
    background-color: var(--primary-light-color);
}

.modal h3 {
    text-wrap: balance;
    line-height: 1;
}

.modal-success .modal-header::after {
    background-color: var(--success-color);
}

.modal-danger .modal-header::after {
    background-color: var(--danger-color);
}

.modal-danger .danger {
    width: 100px;
}


/* Trash icon */
svg.trash {
    max-width: 2rem;
    max-height: 2rem;
}


/* Prevent zoom on safari */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    select,
    textarea,
    input {
        font-size: 16px;
    }
}


/* Media queries */
@media (min-width: 460px) {
    #mainContent {
        min-height: calc(100vh - var(--navbar-height) - var(--footer-height));
    }
}


.font-bold {
    font-weight: bold;
}

.bg-gray-100 {
    background-color: rgb(243, 244, 246);
}
.text-slate-50 {
    color: rgb(248 250 252);
}
.rotate-270 {
    transform: rotate(270deg);
}
.rotate-90 {
    transform: rotate(90deg);
}

.text-base	{
    font-size: 1rem;
}
.text-sm {
    font-size: 0.875rem;
}