.how-it-works {
    max-width: 1200px;
    margin: 0 auto;
}

.how-it-works .image-container {
    position: relative;
    overflow: hidden;
}

.how-it-works .image-container::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6rem;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 8%, rgba(255, 255, 255, 0));
    z-index: 15;
}

.how-it-works .pet-image {
    z-index: 10;
    position: relative;
    max-height: 300px;
}

.how-it-works .title-content {
    margin: auto 0;
}

.how-it-works h2.title {
    font-size: 44px;
    font-weight: 800;
    text-align: center;
    line-height: 1;
    margin-top: 2rem;
    border-bottom: none!important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.how-it-works .logo-full-colorful {
    max-width: 200px;
    margin-top: -1.5rem;
}

.how-it-works .divider {
    bottom: -2px;
    height: 2px;
    z-index: 5;
}

.how-it-works .slick-slider {
    max-width: 100%;
}


/* Arrows */
.how-it-works .slick-slider .slick-arrow.slick-prev {
    left: -1rem;
}

.how-it-works .slick-slider .slick-arrow.slick-next {
    right: -1rem;
}


.how-it-works .slick-slide {
    display: flex;
    justify-self: center;
    margin-bottom: 2rem;
}

.how-it-works .slick-slide :first-child {
    margin: 0 auto;
}

@media (min-width: 666px) {    
    .how-it-works .logo-full-colorful {
        max-width: 300px;
    }

    .how-it-works .divider {
        bottom: 85px;
    }
}

@media (min-width: 1200px) {
    .how-it-works .pet-image {
        max-height: 400px;
    }

    .how-it-works h2.title {
        font-size: 60px;
        margin-top: 5rem;
    }
    
    .how-it-works .logo-full-colorful {
        max-width: 500px;
        margin-top: 0.5rem;
    }
    
    .how-it-works .divider {
        bottom: 4rem;
    }
}