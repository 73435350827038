.slick-slider {
    display: flex;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
}


/* Arrows */
.reviews .slick-slider .slick-arrow.slick-prev {
    left: -1rem;
}

.reviews .slick-slider .slick-arrow.slick-next {
    right: -1rem;
}


.reviews .slick-slide {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

.reviews h2 {
    font-size: 45px;
    margin-bottom: 4rem;
    color: var(--dark-light-color);
}

.review {
    width: 300px;
    height: 100%;
    min-height: 380px;
    border-radius: 3rem;
}

.review .name-pet {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--dark-light-color);
}

.review .stars svg {
    filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.15));
}

.review .image {
    position: absolute;
    bottom: calc(-125px / 2);
    border-radius: 50%;
    overflow: hidden;
    width: 125px;
    height: 125px;
    border-top: 12px solid var(--dark-light-color);
    border-right: 12px solid var(--dark-light-color);
    border-bottom: 12px solid var(--primary-color);
    border-left: 12px solid var(--primary-color);
    rotate: -45deg;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review .image img {
    height: 105px;
    rotate: 45deg;
    max-width: unset;
}

.review .image.review-1 img {
    margin-top: 10px;
    margin-left: 4px;
}

.review .image.review-2 img {
    margin-top: 10px;
    margin-left: 4px;
}

.review .image.review-3 img {
    margin-top: 20px;
    margin-left: 16px;
}

.review .image.review-4 img {
    margin-top: -8px;
    margin-left: -8px;
}
