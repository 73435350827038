#mainContent {
    margin-top: 80px;
    padding: 2rem;
}

#mainContent.is-loading {
    display: flex;
    justify-content: center;
    align-items: center;
}
