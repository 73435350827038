#page404 p {
    font-family: 'GothamBold';
    font-size: 2.25rem;
    letter-spacing: 0.125rem;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.125);
    text-align: center;
}

#page404 h2 {
    font-family: 'Gotham';
    font-size: 1.75rem;
    letter-spacing: 0.125rem;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.125);
}



#page404 #mainContent .paw-rotated-bold-icon.paw-rotated-bold-icon {
    content: '';
    bottom: 0;
    right: -2rem;
    width: 100%;
    max-width: 400px;
    fill: rgba(250, 184, 76, 0.25);
    rotate: 120deg;
    z-index: 1;
}
