.page .paw-rotated-bold-icon {
    fill: rgba(210, 210, 210);
    opacity: 0.5;
    width: 400px;
    z-index: 0!important;
}

.page .paw-rotated-bold-icon.paw-left {
    top: 85%;
    left: -5rem;
    rotate: 180deg;
}

.page .paw-rotated-bold-icon.paw-right {
    top: 0px;
    right: -5rem;
    rotate: 125deg;
}

@media (max-width: 1200px) {
    .page .paw-rotated-bold-icon.paw-left {
        left: -6rem;
    }
    
    .page .paw-rotated-bold-icon.paw-right {
        right: -6rem;
    }
}

@media (max-width: 992px) {
    .page .paw-rotated-bold-icon {
        width: 350px;
    }

    .page .paw-rotated-bold-icon.paw-left {
        left: -7rem;
    }
    
    .page .paw-rotated-bold-icon.paw-right {
        right: -7rem;
    }
}

@media (max-width: 768px) {
    .page .paw-rotated-bold-icon {
        opacity: 0.4;
        width: 300px;
    }

    .page .paw-rotated-bold-icon.paw-left {
        left: -8rem;
    }
    
    .page .paw-rotated-bold-icon.paw-right {
        right: -8rem;
    }
}
