#navbar {
    height: 80px;
    position: fixed;
    z-index: 11;
}

#navbar header {
    position: relative;
    padding: 0 2rem;
}


#navbar .logo-full {
    width: 200px;
}

#navbar #desktopMenu {
    display: none;
}


/* Mobile Logo */
#navbar #logoMobile {
    position: absolute;
    top: 0;
    left: 0;
}


/* Hamburger menu */
#navbar #hamburgerMenu {
    position: absolute;
    top: 0;
    left: 1rem;
    z-index: 2;
}

#navbar #hamburgerMenu button {
    min-width: 4rem;
}

/* Change the navbar menu icon */
#navbar .before\:transition-transform::before,
#navbar .after\:transition-transform::after {
    width: 30px;
    height: 4px;
}

/* Change the navbar menu icons position */
#navbar .group[data-open=true] .group-data-\[open\=true\]\:before\:translate-y-px::before {
    --tw-translate-y: 4px;
}

/* Logo */
#navbar .logo-full {
    width: 150px;
    fill: white;
}

@media (min-width: 1024px) {
    #navbar #desktopMenu {
        display: flex;
        justify-content: space-between;
    }

    #navbar header {
        padding: 0;
    }    

    #navbar #logoMobile {
        display: none;
    }

    #navbar #hamburgerMenu {
        display: none;
    }

    /* #navbar .desktop-nav-item {
        position: relative;
    }

    #navbar .desktop-nav-item:hover :after {
        position: absolute;
        bottom: 0;
        content: '';
        width: 70%;
        height: 2px;
        background-color: white;
    } */
}



/* Side navbar */
.side-nav {
    position: fixed;
    top: 80px;
    left: -75%;
    height: calc(100vh - 80px);
    width: 75%;
    background-color: var(--primary-color);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    border: none;
}
  
.side-nav a {
    display: flex;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0.75rem 1rem;
    margin-left: 2rem;
    transition: 0.3s;
    position: relative;
}

.side-nav a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 2rem);
    height: 1px;
    background-color: #fff;
}
  
.side-nav a:hover {
    color: #f1f1f1;
}

.side-nav.open {
    left: 0;
}